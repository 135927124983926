html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Quicksand", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background-color: #022c43;
}

* {
  box-sizing: border-box;
}

input:focus {
  outline: none;
}

a:visited {
  color: inherit;
}
a {
  text-decoration: none;
}

::placeholder {
  color: #bbb;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
